<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">
    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Experiencia
        <!-- Canales de experiencia -->
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        De acuerdo a tu experiencia laboral responde las siguientes preguntas, y si aplica después selecciona todos los canales en los que hayas trabajado.
        <!-- De acuerdo a tu experiencia laboral selecciona todos los canales en los que hayas trabajado. --> 
      </div>
    </div>

    <ValidationObserver ref="formstepPiK" tag="div"  class="ppi_contentZone spacerRowTop48" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPik"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPikReset"
        class="formDetailZone "
      >

        <!-- Campo de TIENES experiencia (radios) -->
        <div class="dataSection_container spacerRowTop48">
          <div class="dataSection_title">¿Tienes experiencia laboral?</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
                <ValidationProvider 
                  ref="fldx_tieneexp" name="tiene_experiencia" tag="div" :immediate="true" 
                  class="col-12 canna_forma_campo_container"  style="margin-left: -10px;"
                  :rules=" { required: true, oneOf: z_allowTieneExp } "
                  v-slot="{ errors, invalid, validated  }"
                >
                    <input type="hidden" name="tiene_experiencia"  v-model="frmData.tieneExp">
                    <q-option-group
                      class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
                      inline
                      name="tiene_Expertise"
                      type="radio"
                      keep-color
                      :options="[
                        { label: 'Si', value: 99, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                        { label: 'No', value: 100, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }
                      ]"
                      :color="(invalid && validated) ? 'positive' : 'canna_ColorRadio'"
                      v-model="frmData.tieneExp"
                    />
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
                      >
                      {{ errors[0] }}
                      </div>
                    </template>
                </ValidationProvider>
            </div>
          </div>
        </div>

        <!-- Campo de Tiempo de experiencia (select) -->
        <div class="dataSection_container" v-show="(frmData.tieneExp===99)">
          <div class="dataSection_title">¿Cuántos años de experiencia laboral tienes?</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
              <div class="col col-xs-12 col-md-6 canna_forma_campo_container" style="padding-bottom: 0px;"
                :style=" ( is_Mobile === true || $q.screen.lt.md ) ? '':' max-width:400px !important;'" 
              ><!-- padding-left: 8px; -->
                <ValidationProvider 
                  ref="fldx_tiempoexp" name="tiempo_experiencia" :immediate="false"
                  :rules=" { 
                    required: (frmData.tieneExp===99) ? true : false,
                    oneOf: z_allowTiempoExper 
                  } " 
                  v-slot="{ errors, invalid, validated  }"
                >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect" :class="{'fieldError':invalid && validated}">
                        años de experiencia
                      </div>
                      <div class="requiredLabel text-right" >&nbsp;</div>
                    </div>
                    <q-select
                      name="tiempo_experiencia"
                      v-model="frmData.tiempoExp"
                      filled
                      :dense="true"
                      :label="(frmData.tiempoExp ==='' || frmData.tiempoExp === null) ? 'Elige una opción' : ''"
                      :options="cat_list_TiempoExper"
                      option-value="id"
                      option-label="nombre"
                      emit-value
                      map-options
                      :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? errors[0].replace('tiempo_experiencia','Años de experiencia').replace('El campo','') : '' "
                    >
                      <template v-slot:append>
                        <q-icon v-if="frmData.tiempoExp !== null" class="cursor-pointer" name="clear" @click.stop="frmData.tiempoExp = null" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label>
                              {{scope.opt.nombre}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>

                    </q-select>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>

        <div class="dataSection_container spacerRowTop28 row" v-show="(frmData.tieneExp===99)">

          <div class="col-sm-12 col-md-12 col-12" style="margin-bottom:16px;">
            <div class="dataSection_title">Canales de experiencia</div>
          </div>

          <!-- Validador general de canales de experiencia --> 
          <ValidationProvider 
            tag='div' :ref="'fldx_canalesall'" name="canalesAll" :immediate="true"
            class="col-sm-12 col-md-12 col-12"
            :rules=" { 
              required: (frmData.tieneExp===99) ? true : false,
              cannaQuestCanalesMin:1
            }"
            v-slot="{ errors, invalid, validated }"
          >
            <input type="hidden" name="canalesAll" v-model="canales_fullVals">

            <template v-if="(invalid && validated && errors[0] !==undefined)">

              <div class="notifyZone noselect notifyZoneRemove" style="margin-left: 0px; width: 100% !important;margin-top: 0px;">
                <div style="flex: 1 1 0%; justify-content: center;padding: 0px 20px;display: flex;align-items: center;">
                    {{ pib_canalesAllMsg(errors[0]) }}
                </div>
                <!-- 
                <div style="margin-right: 14px; display:none;" >
                    <div class="closenotifyZoneIcon action_item" @click.stop="pib_doResetCanalesAll">
                      <q-icon name="clear" style="color:#ffffff; font-size: 20px;" />
                    </div>
                </div>
                -->
              </div>

            </template>
          </ValidationProvider>


          <div class="col-sm-12 col-md-6" :style="(is_Mobile|| $q.screen.lt.md) ? '' : 'max-width: 320px;'">

            <div class="fieldLabel labelFx_Field text-left noselect">Generales</div>
            <!--  <div class="dataSection_title">Generales</div> -->
            <div class="datasectionItem dataSection_separadorx">

              <div class="row">
                <template v-for="(canal, index) in cat_Generales.dataCatalog">
                  <div class="col-12" :key="'canalGen_' + index">
                    <template v-if="canal.id===116">

                      <q-checkbox v-model="frmData.generales"
                        :label="canal.nombre" color="cannaMainColor" :val="canal.id" 
                        @input="pib_changeGeneralesOtros"
                      />

                      <div style="padding-left: 10px;"
                        :style="(is_Mobile || $q.screen.lt.sm) ? '' : 'max-width: 330px;'"
                      >
                        <ValidationProvider :rules=" {
                          required: (frmData.tieneExp===100) ? false : (val_generalesHasOtros===false) ? false : true, 
                          min:4, 
                          max:40, 
                          cannaTextOtros:true 
                        }"
                          :immediate="true"
                          :ref="'fldx_generalesOtro'"
                          name="generalesOtro" v-slot="{ errors, invalid, validated  }"
                        >
                          <q-input
                            filled
                            name="generalesOtro"
                            maxlength="40"
                            v-model="frmData.generales_otro"
                            hint=""
                            :dense="true"
                            :label="(frmData.generales_otro==='' || frmData.generales_otro=== null)?'Por favor, especifica':''"
                            :error="invalid && validated"
                            :error-message="(errors[0] !==undefined) ? errors[0].replace('generalesOtro','Otro (descripción)') : '' "
                            :disable="(val_generalesHasOtros===false)? true: false"
                          />
                        </ValidationProvider>
                      </div>
                    </template>
                    <template v-else>
                      <q-checkbox v-model="frmData.generales" 
                        :label="canal.nombre" color="cannaMainColor" :val="canal.id" 
                      />
                    </template>
                  </div>
                </template>
              </div>

            </div>
          </div>

          <div class="col-sm-12 col-md-6" :style="(is_Mobile || $q.screen.lt.md) ? '' : 'margin-left:48px; max-width: 320px;'">
            <div class="fieldLabel labelFx_Field text-left noselect">Especializados</div>
            <!-- <div class="dataSection_title">Especializados</div> -->
            <div class="datasectionItem dataSection_separadorx">

              <div class="row">
                <template v-for="(canal, index) in cat_Especiales.dataCatalog">
                  <div class="col-12" :key="'canalEsp_' + index">
                    <template v-if="canal.id===126">

                      <q-checkbox v-model="frmData.especiales"
                        :label="canal.nombre" color="cannaMainColor" :val="canal.id" 
                        @input="pib_changeEspecialesOtros"
                      />

                      <div style="padding-left: 10px;" :style="(is_Mobile || $q.screen.lt.sm) ? '' : 'max-width: 330px;'">
                        <ValidationProvider :rules=" { 
                          required: (frmData.tieneExp===100) ? false : (val_especialesHasOtros===false) ? false : true, 
                          min:4,
                          max:40, 
                          cannaTextOtros:true 
                        }"
                          :immediate="true"
                          :ref="'fldx_especialesOtro'"
                          name="especialesOtro" v-slot="{ errors, invalid, validated  }"
                        >
                          <q-input
                            filled
                            name="especialesOtro"
                            maxlength="40"
                            v-model="frmData.especiales_otro"
                            hint=""
                            :dense="true"
                            :label="(frmData.especiales_otro==='' || frmData.especiales_otro=== null)?'Por favor, especifica':''"
                            :error="invalid && validated"
                            :error-message="(errors[0] !==undefined) ? errors[0].replace('especialesOtro','Otro (descripción)') : '' "
                            :disable="(val_especialesHasOtros===false)? true: false"
                          />
                        </ValidationProvider>
                      </div>
                    </template>
                    <template v-else>
                      <q-checkbox v-model="frmData.especiales" 
                        :label="canal.nombre" color="cannaMainColor" :val="canal.id" 
                      />
                    </template>
                  </div>
                </template>
              </div>

            </div>
          </div>
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from './../../../../boot//axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'


const tplpik_Data = {
  tieneExp: 99 , //Por default
  tiempoExp: null ,
  generales: [],
  generales_otro: '',
  especiales: [],
  especiales_otro: ''
}

export default {
  name: 'psi_screen_canales',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      cat_list_TiempoExper: [],
      z_allowTieneExp: [99,100],
      z_allowTiempoExper: [],
      z_allowGenerales: [],
      z_allowEspeciales: [],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify(tplpik_Data)),
      frmData: JSON.parse(JSON.stringify(tplpik_Data))
    }
  },
  computed: {
    cat_tieneExp (){ // catalogo id = 14  Tiene experiencia  99: SI  100:NO 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(14) : this.getHelperCat(14)
    },
    cat_tiempoExpertice (){ // catalogo id = 55  Buscador de tiempo de experiencia laboral
      // NOTA no usar valor 735 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(55) : this.getHelperCat(55)
    },
    cat_Generales () { // catalogo id = 17  Canal de experiencia generales
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(17) : this.getHelperCat(17)
    },
    cat_Especiales () { // catalogo id = 18  Canal de experiencia especializados
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(18) : this.getHelperCat(18)
    },
    canales_fullAllowVals () {
      var prueba = this.z_allowGenerales.slice(0) 
      var pruebab = this.z_allowEspeciales.slice(0)
      return prueba.concat(pruebab)
    },
    canales_fullVals (){ 
      var prueba = this.frmData.generales.slice(0) 
      var pruebab = this.frmData.especiales.slice(0)
      return prueba.concat(pruebab)
    },
    val_generalesHasOtros () {
      var test = false
      if (this.frmData.generales !== undefined) {
        if (this.frmData.generales.length) {
          test = (this.frmData.generales.indexOf(116) >= 0) ? true : false
        }
      }
      return test
    },
    val_especialesHasOtros () {
      var test = false
      if (this.frmData.especiales !== undefined) {
        if (this.frmData.especiales.length) {
          test = (this.frmData.especiales.indexOf(126) >= 0) ? true : false
        }
      }
      return test
    },
    mainErrorsZZ () { 
      if (this.$refs.formstepPiK) {
        return this.$refs.formstepPiK.errors
      } else { 
        return (-1)
      }
    },
    mainErrorsForm (){ 
       if (this.zinitScreen) {
         if (this.$refs.formstepPiK) {
           return this.$refs.formstepPiK
         } else { 
           return (-1)
         }
       } else { 
         return 0
       }
    },
    mainErrors () {
      var countx = 0
      if (this.zinitScreen) {
        try {
          if (this.$refs.formstepPiK) {
            if (this.$refs.formstepPiK.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiK.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiK.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {
    'frmData.tieneExp' : function(newVal, oldVal){
      // // catalogo id = 14  Tiene experiencia  99: SI  100:NO 
      var test=11
      if (newVal !== oldVal ) { 
        if (newVal === 100){ // NO

            this.frmData.tiempoExp = null
            this.frmData.generales = []
            this.frmData.especiales = []
            this.frmData.generales_otro = ''
            this.frmData.especiales_otro = ''

            this.$forceUpdate()

            // Resetear fields  (tiempo Exp y Canales all)
            if (this.$refs.fldx_tiempoexp){ this.$refs.fldx_tiempoexp.reset() }
            if (this.$refs.fldx_canalesall){ this.$refs.fldx_canalesall.reset() }
            if (this.$refs.fldx_generalesOtro){ this.$refs.fldx_generalesOtro[0].reset() }
            if (this.$refs.fldx_especialesOtro){ this.$refs.fldx_especialesOtro[0].reset() }
            this.$forceUpdate()

            setTimeout(() => {
              this.$refs.formstepPiK.validate()
            }, 1000)

        } else {

          // validar fields  (tiempo Exp y Canales all)
          this.$forceUpdate()
          setTimeout(() => {
            if (this.$refs.fldx_tiempoexp){ 
              this.$refs.fldx_tiempoexp.validate().then( (response) => { test = 22 })
            }
            if (this.$refs.fldx_canalesall){ 
              this.$refs.fldx_canalesall.validate() .then( (response) => { test = 33})
            }
          }, 1000)
        }
      }
    }
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_canales --   test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/aptitudes-a'
      this.$cannaDebug('-- psiPage_canales --   do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPikReset (){ 

    },
    pib_doResetCanalesAll:function(){ 
      if (this.$refs.fldx_canalesall){
        this.$refs.fldx_canalesall.reset()
      }
    },
    pib_canalesAllMsg:function( errorx ){ 
      if (errorx === undefined || errorx === null || errorx ===''){ 
        return ''
      }
      var strbase = errorx.replace('canalesAll','').replace('El campo','')
      if (strbase.trim() ==='es obligatorio'){ 
        strbase = '¡Espera! Debes seleccionar mínimo 1 opción en está sección.'
      }
      //var test == (errors[0] !==undefined) ? errors[0] : ''
      return strbase
    },
    pib_changeGeneralesOtros: function (valuex, event) {
      this.$cannaDebug('-- psiPage_canales --   change Puestos AdmOtros --- ')
      var test = 11
      var currval = valuex 
      if (currval.indexOf(58) < 0) {
        this.frmData.generales_otro = null
        this.$refs.fldx_generalesOtro[0].reset()
      }
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fldx_generalesOtro[0].validate().then((response) => { 
          test = 22
        })
      }, 200)
    },
    pib_changeEspecialesOtros: function (valuex, event) {
      this.$cannaDebug('-- psiPage_canales --   change Puestos Opera Otros --- ')
      var test = 11
      var currval = valuex 
      if (currval.indexOf(58) < 0) {
        this.frmData.especiales_otro = null
        this.$refs.fldx_especialesOtro[0].reset()
      }
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fldx_especialesOtro[0].validate().then((response) => { 
          test = 22
        })
      }, 200)
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_canales --   do Submit Data --- ' + newRuta)
      var test = 11 
      var toAdd = [], toRemove = [], toPatch = []
      var tmpArrTest = null
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1) 

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 

      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...!  // && this.canales_fullVals.length>0  
        // Procedemos a guardar 
        test = 22

        // Verificar Tiene ExPeriencia
        if (testA._.tieneExp.status === 'MODIFIED' && testA._.tieneExp.current === 100 ) {
          // Si es no automatico se borran los otros valores  
          toAdd.push(testA._.tieneExp.current)
          if (testA._.tieneExp.original === 99 &&  this.profileRecords.length>0){
            var tmpSiTest = this.profileRecords.filter(item => item.catalogo_id === 99) // Tiene que existir
            if (tmpSiTest.length>=0){ 
              toRemove.push(99)
            }
          }
        } else if (
          (testA._.tieneExp.status === 'MODIFIED' && testA._.tieneExp.current === 99) || 
          (testA._.tieneExp.status === 'EQUAL' && testA._.tieneExp.current === 99)
        ) { // Modificado q diga si  o la primera vez q no hay registros 

          // tienes experiencia
          if (testA._.tieneExp.status === 'MODIFIED') {
            if (testA._.tieneExp.original === 100){
              toRemove.push(100)
            }
            toAdd.push(testA._.tieneExp.current)
          } else if (testA._.tieneExp.status === 'EQUAL' &&  this.profileRecords.length<=0) {
            toAdd.push(testA._.tieneExp.current)
          }

          // Años de experiencia
          if (testA._.tiempoExp.status === 'MODIFIED') {
            if (testA._.tiempoExp.original!==null){
              tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === testA._.tiempoExp.original) // Tiene que existir
              if (tmpArrTest.length>=0){ 
                toRemove.push(testA._.tiempoExp.original)
              }
            }
            toAdd.push(testA._.tiempoExp.current)
          }

          // canales generales
          if (testA._.generales.status === 'MODIFIED') {
            testA._.generales._.forEach(item => {
              if (item.status === 'ADDED') {
                if (item.current === 116) {
                  toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.generales_otro })
                } else {
                  toAdd.push(item.current)
                }
              } else if (item.status === 'MODIFIED') {
                toRemove.push(item.original)
                if (item.current === 116) {
                  toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.generales_otro })
                } else {
                  toAdd.push(item.current)
                }
              } else if (item.status === 'DELETED') {
                toRemove.push(item.original)
              }
            })
          }

          // Canales especiales
          if (testA._.especiales.status === 'MODIFIED') {
            testA._.especiales._.forEach(item => {
              if (item.status === 'ADDED') {
                if (item.current === 126) {
                  toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.especiales_otro })
                } else {
                  toAdd.push(item.current)
                }
              } else if (item.status === 'MODIFIED') {
                toRemove.push(item.original)
                if (item.current === 126) {
                  toAdd.push({ catalogo_id: item.current, otro: '' + this.frmData.especiales_otro })
                } else {
                  toAdd.push(item.current)
                }
              } else if (item.status === 'DELETED') {
                toRemove.push(item.original)
              }
            })
          }

          if (testA._.generales_otro.status === 'MODIFIED') {
            var tmpArrW = toRemove.indexOf(116)
            var tmpArrZ = this.profileRecords.filter(item => item.catalogo_id === 116) // Tiene que existir
            if (tmpArrZ.length > 0 && tmpArrW < 0) { // Solo si existe el registro ..! para aplicar en PATCH
              toPatch.push([tmpArrZ[0].catalogo_id, this.frmData.generales_otro])
            }
          }

          if (testA._.especiales_otro.status === 'MODIFIED') {
            var tmpArrX = toRemove.indexOf(126)
            var tmpArrY = this.profileRecords.filter(item => item.catalogo_id === 126) // Tiene que existir
            if (tmpArrY.length > 0 && tmpArrX < 0) { // Solo si existe el registro ..! para aplicar en PATCH
              toPatch.push([tmpArrY[0].catalogo_id, this.frmData.especiales_otro])
            }
          }

        }

        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')

        // if (test!=333) { 
        //   alert('Salida de escape')
        //   return false 
        // }

        // ----------------------
        // Registros a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se hace path 
        if (toPatch.length > 0) {
          var toPatchReqs = []
          for (const pxRC of toPatch) {
            toPatchReqs.push(this.createAxiosConfig ('patch', mcModel, { 
              delimiters: [pxRC[0]],
              data: { otro: '' + pxRC[1] }
            }, true, null))
          }
          for (const patchRQx of toPatchReqs) {
            if (reqErr.patch <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataPatch', reqRes.patch, patchRQx).then((resx) => {
                var tmpresponseData = reqRes.patch[reqRes.patch.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.patch += 1
                }
              })
            }
          }
          if (reqErr.patch > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.patch[0], reqErr.patch)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        // Actualizar Datos de Estatus // WIP 

        // -----------
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData)) 
        allowNextPage = true

      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null

      if (this.currProfile_status < this.zstatUser.CANALES_EXPERIENCIA ){ // < 23 
        var newEstatus  = (this.frmData.tieneExp === 99) ? this.zstatUser.CANALES_EXPERIENCIA : this.zstatUser.LABORAL_EXPERIENCIA
        await this.hpb_updateProfileStatus( newEstatus ).then(respx=>{
          retUPS = respx
        })
      } else { 
        if (toRemove.length > 0 || toPatch.length > 0 || toAdd.length > 0){

          var newEstatus  = (this.frmData.tieneExp === 99) ? this.zstatUser.CANALES_EXPERIENCIA : this.zstatUser.LABORAL_EXPERIENCIA
          if (this.currProfile_status < newEstatus ){
            await this.hpb_updateProfileStatus( this.zstatUser.CANALES_EXPERIENCIA ).then(respx=>{
              retUPS = respx
            })
          }
        }
      }

      //if (toRemove.length > 0 || toPatch.length > 0 || toAdd.length > 0){ // NOTA  DEv-sp 12  si se modificaron datos (nuevo o posterior ) - si tiene experiencia o no Recargar full profile (filtros user y ultimos empleos)
        await this.hpb_refreshProfileInfo ().then(respx=>{
          retUPS = respx
        })
        await this._waitRequestProc(100);
      //}

      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        // NOTA  DEv-sp 12  si si tiene experiencia solicitar  (ultimos empleos) de lo contrario  nos vamos a curriculum 
        var newRuta = (this.frmData.tieneExp === 99) ? '/dashboard/personal/aptitudes-c' : '/dashboard/personal/aptitudes-d' //Ult empleos  o Curriculum        
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { categoria_id: '14,55,17,18', 'per-page': 'all', expand:'catalogo'}, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpik_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpik_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        // alert('llenando datos')
        var valdt_tieneExp = null 
        var valdt_tiempoExp = null
        var dt_generales = [] 
        var dt_generalOtro = ''
        var dt_especiales = [] 
        var dt_especialOtro = ''

        //Sacamos Si tiene eXP (solo un valor)
        var tieneExpArr = profileData.filter(itemP => itemP.categoriaId === 14)
        if (tieneExpArr.length>=1){
          valdt_tieneExp =  tieneExpArr[0].catalogo_id 
        } else { 
          valdt_tieneExp = 99 //Por default = si 
        }

        //Sacamos el Tiempo de EXP (solo un valor)
        var tiempoExpArr = profileData.filter(itemP => itemP.categoriaId === 55)
        if (tiempoExpArr.length>=1){
          valdt_tiempoExp =  tiempoExpArr[0].catalogo_id 
        } else { 
          valdt_tiempoExp = null //Por default = null 
        }

        // Verificamos los canales de EXP 
        profileData.forEach(itemP => {
            if (itemP.categoriaId === 17) { //generales
              dt_generales.push(itemP.catalogo_id)
              if (itemP.catalogo_id === 116){ 
                dt_generalOtro = '' + itemP.otro 
              }
            } else  if (itemP.categoriaId === 18) { //especiales
              dt_especiales.push(itemP.catalogo_id)
              if (itemP.catalogo_id === 126){ 
                dt_especialOtro = '' + itemP.otro 
              }
            }
        })

        var newData = {
          tieneExp: valdt_tieneExp, 
          tiempoExp: valdt_tiempoExp,
          generales: dt_generales,
          generales_otro: dt_generalOtro ,
          especiales: dt_especiales,
          especiales_otro: dt_especialOtro
        }

        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpik_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpik_Data))
      }
      return true 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_canales --   beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_canales --   created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_canales --    beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_canales --   mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)

    if (this.$refs.fldx_tiempoexp){ this.$refs.fldx_tiempoexp.reset() }
    if (this.$refs.fldx_canalesall){ this.$refs.fldx_canalesall.reset() }

    var categorias = [17, 18, 14, 55]
    for (let categx of categorias) { 
      if (this.getHelperCat(categx).dataLoaded===false){ 
        var testCatA = false 
        await this.helpermix_getCatalog (categx, null).then( respx => { 
          this.$cannaDebug('-- psiPage_canales -- categoria ' + categx  + ' finish ')
          testCatA = respx 
        })
      }
      await this._waitRequestProc(100)
    }
    await this._waitRequestProc(200)

    var generalVals = []
    var especialesVals = []
    var tiempoExpVals = []
    var tiempoExpList = []

    this.getHelperCat(17).dataCatalog.forEach( item => { 
      generalVals.push(item.id)
    })
    this.getHelperCat(18).dataCatalog.forEach( item => { 
      especialesVals.push(item.id)
    })
    this.getHelperCat(55).dataCatalog.forEach( item => { 
      if (item.id!==735) {  // incluir todos los valores q no sean el 735 :: Cualquier tiempo de experiencia
        tiempoExpVals.push(item.id)
        tiempoExpList.push(JSON.parse(JSON.stringify(item)))
      }
    })

    this.z_allowGenerales = generalVals
    this.z_allowEspeciales = especialesVals

    this.z_allowTiempoExper = tiempoExpVals
    this.cat_list_TiempoExper = tiempoExpList


    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()

    if (this.$refs.fldx_tiempoexp){ this.$refs.fldx_tiempoexp.reset() }
    if (this.$refs.fldx_canalesall){ this.$refs.fldx_canalesall.reset() }

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_canales --   beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_canales --   updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_canales --   activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      await this._waitRequestProc(200)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.$forceUpdate()

      await this._waitRequestProc(100)

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_canales --   deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_canales --   beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_canales --   destroyed')
  }
}
</script>
